import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { metrics } from './const';


const Itog = () => {
  const data = metrics;
  
  const config = {
    data,
    padding: 'auto',
    xField: 'version',
    yField: 'result',
    
    lineStyle: {
      stroke: '#ffd700',
      lineWidth: 4,
      //lineDash: [4,5],
      //strokeOpacity: 0.7,
      //shadowColor: 'black',
      //shadowBlur: 10,
      //shadowOffsetX: 5,
      //shadowOffsetY: 5,
      cursor: 'pointer'
    },
        
    annotations: [
      // {
      //   type: 'regionFilter',
      //   start: ['min', '49'],
      //   end: ['max', '60'],
      //   color: '#ffd700',
      // },

      {
        type: 'regionFilter',
        start: ['min', '49'],
        end: ['max', '0'],
        color: '#F4664A',
      },

            {
        type: 'regionFilter',
        start: ['min', '90'],
        end: ['max', '100'],
        color: 'darkseagreen',
      },
      // {
      //   type: 'text',
      //   position: ['min', 'median'],
      //   content: 'Средняя',
      //   offsetY: -4,
      //   style: {
      //     textBaseline: 'bottom',
      //   },
      // },
      // {
      //   type: 'line',
      //   start: ['min', 'median'],
      //   end: ['max', 'median'],
      //   style: {
      //     stroke: '#F4664A',
      //     lineDash: [2, 2],
      //   },
      // },
    ],
  };


  return (
  <>
    <div style={{marginBottom: '10px'}}><b>Оценка производительности Google</b> <br /><b><span style={{color: "orangered"}}>0 .. 49 - плохо</span> <span style={{color: "gold"}}>50 .. 89 - хорошо</span> <span style={{color: "darkseagreen"}}>90 .. 100 - отлично</span></b></div>
    <Line {...config} />
  </>
  );
};

export default Itog;